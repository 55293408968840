var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('TheTitle',{attrs:{"text":_vm.title,"icon":_vm.pageIcon}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8","sm":"10","cols":"10"}},[_c('v-text-field',{attrs:{"placeholder":"Procurar","dense":"","solo":"","background-color":"#FFFFFF","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"md":"4","sm":"4","cols":"4"}},[_c('v-btn',{attrs:{"block":"","color":"amber darken-4","dark":""},on:{"click":function($event){return _vm.dialogSave()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1)],1)],1),_c('v-card',{staticClass:"pa-5 full-height"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.objects,"locale":"pt-BR","search":_vm.search},scopedSlots:_vm._u([{key:"item.purposeType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.purposeType))+" - "+_vm._s(_vm.getDataGroupName(item.purposeDatagroup))+" ")]}},{key:"item.idProbability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProbability(item.idProbability))+" ")]}},{key:"item.idImpact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getImpact(item.idImpact))+" ")]}},{key:"item.risk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRisk(item.risk))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('TheToolTip',{attrs:{"label":"Editar"}},[_c('v-btn',{attrs:{"icon":"","small":"","tile":""}},[_c('v-icon',{on:{"click":function($event){return _vm.dialogUpdate(item)}}},[_vm._v("mdi-pen")])],1)],1),_c('TheToolTip',{attrs:{"label":"Remover"}},[_c('v-btn',{attrs:{"icon":"","small":"","tile":""}},[_c('v-icon',{on:{"click":function($event){return _vm.remove(item.id)}}},[_vm._v("mdi-minus")])],1)],1)]}}],null,true)})],1),_c('TheDialog',{attrs:{"title":_vm.title,"icon":_vm.pageIcon,"width":"1200px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){return _vm.resolveForm()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),(!_vm.edit)?_c('span',[_vm._v("Adicionar")]):_vm._e(),(_vm.edit)?_c('span',[_vm._v("Atualizar ")]):_vm._e()],1),_c('v-spacer'),_c('span',{staticClass:"ml-5 caption"},[_c('sup',[_vm._v("*")]),_vm._v("Campos obrigatórios")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-form',{ref:"form1",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"8","sm":"12","cols":"12"}},[_c('b',[_vm._v(" Nome "),_c('sup',[_vm._v("*")])]),_c('v-text-field',{staticClass:"my-2",attrs:{"placeholder":"Nome","dense":"","outlined":"","color":"primary","rules":[_vm.requiredRule, _vm.spaceRule]},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('b',[_vm._v(" Setor ")]),_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.sector,"item-value":"id","item-text":"name","dense":"","hide-details":"","outlined":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","x-small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('sup',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.object.sectorList.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.object.sectorList),callback:function ($$v) {_vm.$set(_vm.object, "sectorList", $$v)},expression:"object.sectorList"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('b',[_vm._v(" Probabilidade de Ocorrência ")]),_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.probability,"dense":"","hide-details":"","outlined":"","clearable":""},model:{value:(_vm.object.idProbability),callback:function ($$v) {_vm.$set(_vm.object, "idProbability", $$v)},expression:"object.idProbability"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('b',[_vm._v(" Impacto ")]),_c('v-select',{staticClass:"my-2",attrs:{"items":_vm.impact,"dense":"","hide-details":"","outlined":"","clearable":""},model:{value:(_vm.object.idImpact),callback:function ($$v) {_vm.$set(_vm.object, "idImpact", $$v)},expression:"object.idImpact"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12","cols":"12"}},[_c('b',[_vm._v(" Risco ")]),_c('v-text-field',{staticClass:"my-2",attrs:{"disabled":"","dense":"","hide-details":"","outlined":"","color":"primary"},model:{value:(_vm.riskLevelText),callback:function ($$v) {_vm.riskLevelText=$$v},expression:"riskLevelText"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(" Descrição ")]),_c('VueEditor',{staticClass:"mt-2",attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.object.description),callback:function ($$v) {_vm.$set(_vm.object, "description", $$v)},expression:"object.description"}})],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }