<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" locale="pt-BR" :search="search">
        <template v-slot:[`item.purposeType`]="{ item }">
          {{ getType(item.purposeType) }} -
          {{ getDataGroupName(item.purposeDatagroup) }}
        </template>
        <template v-slot:[`item.idProbability`]="{ item }">
          {{ getProbability(item.idProbability) }}
        </template>
        <template v-slot:[`item.idImpact`]="{ item }">
          {{ getImpact(item.idImpact) }}
        </template>
        <template v-slot:[`item.risk`]="{ item }">
          {{ getRisk(item.risk) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200px">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-row>
            <v-col md="8" sm="12" cols="12">
              <b> Nome <sup>*</sup></b>
              <v-text-field
                placeholder="Nome"
                v-model="object.name"
                class="my-2"
                dense
                outlined
                color="primary"
                :rules="[requiredRule, spaceRule]"
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <b> Setor </b>
              <v-select
                :items="sector"
                item-value="id"
                item-text="name"
                v-model="object.sectorList"
                class="my-2"
                dense
                hide-details
                outlined
                clearable
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" color="primary" x-small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <sup v-if="index === 1" class="grey--text text-caption"> (+{{ object.sectorList.length - 1 }}) </sup>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" sm="12" cols="12">
              <b> Probabilidade de Ocorrência </b>
              <v-select
                :items="probability"
                v-model="object.idProbability"
                class="my-2"
                dense
                hide-details
                outlined
                clearable
              ></v-select>
            </v-col>

            <v-col md="4" sm="12" cols="12">
              <b> Impacto </b>
              <v-select
                :items="impact"
                v-model="object.idImpact"
                class="my-2"
                dense
                hide-details
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <b> Risco </b>
              <v-text-field
                v-model="riskLevelText"
                disabled
                class="my-2"
                dense
                hide-details
                outlined
                color="primary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <b> Descrição </b>
              <VueEditor v-model="object.description" :editor-toolbar="customToolbar" class="mt-2" />
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'Dados',

  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
    VueEditor,
  },

  computed: {

    list() {
      for (let i = 0; i < this.activity.length; i++) {
        const element = this.activity[i]
        if (this.object.idActivity === element.id) {
          return element.list
        }
      }
      return []
    },

    riskLevel() {
      if (!this.object || !this.object.idProbability || !this.object.idImpact) {
        return null
      }
      return this.object.idProbability * this.object.idImpact
    },

    riskLevelText() {
      if (!this.riskLevel) {
        return null
      }
      if (this.riskLevel <= 0.12) {
        return 'Risco Baixo'
      }
      if (this.riskLevel > 0.12 && this.riskLevel <= 0.32) {
        return 'Risco Médio'
      }
      return 'Risco Alto'
    },
  },
  
  data: () => ({
    baseRoute: 'vulnerability',
    title: 'Vulnerabilidades',
    pageIcon: 'mdi-shield-lock-outline',

    object: {},
    datagroup: [],
    sector: [],
    people: [],
    objects: [],
    activity: [],

    dialog: false,
    valid: false,
    search: null,
    error: false,
    edit: false,

    header: [
      { text: 'Nome', value: 'name' },
      { text: 'Probabilidade', value: 'idProbability' },
      { text: 'Impacto', value: 'idImpact'},
      { text: 'Risco', value: 'risk' },
      { text: 'Ações', value: 'action', align: "right" },
    ],

    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    
    risks: [
      { text: 'Baixo', value: 1 },
      { text: 'Médio', value: 2 },
      { text: 'Alto', value: 3 },
    ],

    probability: [
      { text: 'Quase certo', value: 1 },
      { text: 'Alta', value: 0.8 },
      { text: 'Média', value: 0.6 },
      { text: 'Baixa', value: 0.4 },
      { text: 'Rara', value: 0.2 },
    ],

    impact: [
      { text: 'Gravíssimo', value: 0.8 },
      { text: 'Grave', value: 0.6 },
      { text: 'Médio', value: 0.4 },
      { text: 'Leve', value: 0.2 },
      { text: 'Sem Impacto', value: 0.05 },
    ],

    type: [
      { text: 'Coleta', value: '1' },
      { text: 'Uso/Finalidade', value: '2' },
      { text: 'Compartilhamento', value: '3' },
      { text: 'Retenção/Armazenamento', value: '4' },
      { text: 'Descarte', value: '5' },
    ],
    
  }),

  methods: {
    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },
    loadActivity() {
      const url = `${baseApiUrl}/activity`
      axios
        .get(url)
        .then((res) => {
          this.activity = res.data
        })
        .catch(showError)
    },
    loadPeople() {
      const url = `${baseApiUrl}/people`
      axios
        .get(url)
        .then((res) => {
          this.people = res.data
        })
        .catch(showError)
    },
    loadSector() {
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.sector = res.data
        })
        .catch(showError)
    },
    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },
    async dialogUpdate(item) {
      const url = `${baseApiUrl}/${this.baseRoute}/${item.id}`
      this.object = await axios
        .get(url)
        .then((res) => {
          return res.data
        })
        .catch(showError)
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      this.object.risk = this.riskLevel

      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      this.object.risk = this.riskLevel

      delete objects.id
      delete objects.activityName
      delete objects.purposeType
      delete objects.purposeDatagroup
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
    getDataGroupName(id) {
      for (let i = 0; i < this.datagroup.length; i++) {
        const element = this.datagroup[i]
        if (element.id === id) {
          return element.name
        }
      }
      return ''
    },
    loadDatagroup() {
      const url = `${baseApiUrl}/datagroup`
      axios
        .get(url)
        .then((res) => {
          this.datagroup = res.data
        })
        .catch(showError)
    },
    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i]
        if (element.value === id) {
          return element.text
        }
      }
    },
    getRisk(risk) {
      if (!risk) {
        return ''
      }
      if (risk <= 0.12) {
        return 'Risco Baixo'
      }
      if (risk > 0.12 && risk <= 0.32) {
        return 'Risco Médio'
      }
      return 'Risco Alto'
    },
    getProbability(id) {
      for (let i = 0; i < this.probability.length; i++) {
        const element = this.probability[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ''
    },
    getImpact(id) {
      for (let i = 0; i < this.impact.length; i++) {
        const element = this.impact[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ''
    },
  },
  mounted() {
    this.loadData()
    this.loadActivity()
    this.loadPeople()
    this.loadSector()
    this.loadDatagroup()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>
